.title,
.formLabel {
  margin: 0 !important;
}

.mainTabsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 24px 24px;
  flex-wrap: wrap;
}