.container {
  background-color: #fff;
  border-radius: 8px;
  padding: 21px 24px;
}

.title {
  color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

.price {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 4px;
}

.select {
  width: 100%;
  margin-bottom: 10px;
}

.progressContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.progressBase {
  background-color: rgba(0, 0, 0, 0.06);
  height: 8px;
  position: relative;
  width: 100%;
}

.progressLine {
  position: absolute;
  left: 0;
  top: 0;
  height: 8px;
  background-color: rgba(22, 119, 255, 1);

  &::after {
    position: absolute;
    content: '';
    height: 14px;
    width: 1px;
    background-color: rgba(22, 119, 255, 1);
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.progressFooter {
  margin-top: 5px;
  color: rgba(134, 134, 134, 1);
  font-size: 8px;
}

.up {
  svg {
    fill: rgba(82, 196, 26, 1);
  }
}

.down {
  svg {
    fill: rgba(255, 77, 79, 1);
  }
}

.footer {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding-top: 9px;
}
