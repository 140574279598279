.tabsContainer {
  display: flex;
  justify-content: center;
}

.tabs {
  [role='tablist'] {
    &::before {
      border-bottom: none;
    }
  }

  :global(.ant-tabs-tab) {
    padding: 9px 16px;
    margin: 0 !important;
  }

  :global(.ant-tabs-tab-active) {
    background-color: #fff;
    border-radius: 6px;
    font-weight: 600;
  }
}

.filtersContainer {
  display: flex;
  justify-content: center;
  column-gap: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.rangePicker {
  width: 322px;
}

.content {
  display: flex;
  justify-content: space-between;
  column-gap: 24px;

  & > div {
    width: calc(33% - 12px);
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}
