.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.lines {
  display: flex;
  justify-content: space-between;

  & > div {
    width: calc(50% - 10px);
  }
}

.progresses {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 20px;

  & > div {
    width: calc(33% - 12px);
  }
}
