.container {
  background-color: #fff;
  border-radius: 6px;
  padding: 20px 24px;
}

.settingsButton {
  cursor: pointer;
  color: rgba(22, 119, 255, 1);
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
}

.title {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settingsContainer {
  background-color: rgba(245, 245, 245, 1);
  padding: 10px 11px;
  margin-bottom: 8px;
}

.select {
  width: 100%;
}

.datesButton {
  border-style: dashed;
}

.rangePicker {
  width: 100%;
}

.rangePickerContainer {
  margin-bottom: 8px;
}

.icon {
  color: rgba(0, 0, 0, 0.45);
  width: 14px;
}
