.container {
  padding: 25px;
  background-color: rgba(240, 245, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(214, 228, 255, 1);
}

.title {
  margin-bottom: 8px;
  color: rgba(47, 84, 235, 1);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.content {
  display: flex;
  column-gap: 24px;
}

.range {
  width: 365px;
}

.period {
  width: 290px;
}
