.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  margin: 0 !important;
}

.headerContainer {
  display: flex;
}
