.header {
  margin-bottom: 24px;
}

.cardActions {
  height: 100%;
  margin-top: 4px;
  padding: 0 24px 4px 24px;
}

.organizationName {
  margin: 0 !important;
}

.nameContainer {
  margin: 0;
  width: 100%;
}

.date {
  font-size: 10px !important;
}

.managerSelect {
  width: 260px;
  height: 26px;
  box-shadow: 0 0 0 1px #0958D9;
  border-radius: 6px;
}
