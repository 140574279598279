.dragger {
  height: 104px;
}

.formContainerDark {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 6px;
  padding: 15px;
}

.formContainerLight {
  background: rgba(240, 246, 255, 1);
  border-radius: 6px;
  padding: 15px;
}

.formLabel {
  margin: 0 !important;
}