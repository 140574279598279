[data-theme='dark'] {
  --statistic-border-color: #303030;
  --statistic-background-color: #1d1d1d;
  --statistic-primary-color: rgba(255, 255, 255, 0.85);
  --statistic-secondary-color: rgba(255, 255, 255, 0.45);
}

[data-theme='light'] {
  --statistic-border-color: rgba(214, 228, 255, 1);
  --statistic-background-color: #F0F5FF;
  --statistic-primary-color: black;
  --statistic-secondary-color: rgba(0, 0, 0, 0.45);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--statistic-background-color);
  border-radius: 6px;
  padding: 5px;
  font-weight: 600;
  line-height: 24px;
  color: var(--statistic-color);
  text-decoration-skip-ink: none;
}

.title {
  color: var(--statistic-secondary-color);
  font-size: 12px;
}

.value {
  font-size: 16px;
  color: var(--statistic-primary-color);
}