.containerCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  gap: 10px 10px;
  flex-wrap: wrap;
}

.containerPostPay {
  border: 1px solid rgba(255, 213, 145, 1);
  background: rgba(255, 247, 230, 1)
}

.cardTitle {
  margin: 0 !important;
}

.managerSelect {
  width: 260px;
  height: 26px;
  box-shadow: 0 0 0 1px #0958D9;
  border-radius: 6px;
}

.balanceBadge {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-width: 100px;
  gap: 8px;
}

.balanceBadge:first-child .badgeNumber {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.balanceBadge:nth-child(2) .badgeNumber {
  border-radius: 0;
}

.balanceBadge:last-child .badgeNumber{
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.badgeNumber {
  padding: 3px 5px 3px 5px;
  background: rgba(186, 224, 255, 1);
  white-space: nowrap;
}

.balanceBadgeNotPay .badgeNumber {
  background: rgba(255, 192, 105, 1);
}

.contractBalanceBadge {
  margin-top: -25px;
  text-align: center;
  border-radius: 16px;
}

.cardContainer {
  padding: 15px;
  display: flex;
  gap: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  transition-duration: .125s;
  position: relative;

  &:hover {
    background: rgba(230, 232, 230, 0.46);
  }
}

.badgeText {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.cardContainer:hover .badgeText {
  opacity: 1;
  transform: translateY(0);
}
