.container {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 24px;
  padding-bottom: 8px;
}

.top {
  display: flex;
  justify-content: space-between;
}

.topDesc {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
}

.topPrice {
  font-size: 30px;
  line-height: 26px;
  font-weight: 600;
}

.topHint {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.45);
}

.refundsNum {
  color: rgba(255, 77, 79, 1);
}

.select {
  width: 100%;
  margin-top: 13px;
  margin-bottom: 24px;
}

.stats {
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    column-gap: 18px;
  }
}

.statItem {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 14px;
  line-height: 22px;
}

.up {
  svg {
    fill: rgba(82, 196, 26, 1);
  }
}

.down {
  svg {
    fill: rgba(255, 77, 79, 1);
  }
}

.footer {
  display: flex;
  column-gap: 8px;
}
